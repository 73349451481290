import React, { useEffect } from "react";
import { deleteCookie, getCookie } from "../../../../functionLib/cookieFuncs";
import getRolesAndUpdateState from "../../../../functionLib/getRolesAndUpdateState";
import { useApi } from "../../../../functionLib/hooks";
import svgLib from "../../../../functionLib/svglib";
import { useAppBot } from "../../../../redux/bot/bot.selectors";
import { useAppDispatch } from "../../../../redux/hooks";
import AppNavBarButtonComponent from "../../../UIComponents/AppNavBarButtonComponent/AppNavBarButtonComponent";
import AppNavBarLogoComponent from "../../../UIComponents/AppNavBarLogoComponent/AppNavBarLogoComponent";
import AppNavProgressBarComponent from "../../../UIComponents/AppNavProgressBarComponent/AppNavProgressBarComponent";
import { AccountIcon } from "./AccountIcon";
import { BotsIcon } from "./BotsIcon";
import {
  AppNavBarButtonsWrapComponent,
  AppNavBarButtonTrComponent,
  AppNavBarComponentMain,
} from "./styles";

const AppNavBarComponent = () => {
  const api = useApi();
  const { botAgent: selectedBot, botId: selectedBotId } = useAppBot();
  const appDispatch = useAppDispatch();

  const checkLogin = async () => {
    try {
      const result = await api.authorize.checkIfLoggedIn();
      if (!result.loggedIn) {
        deleteCookie("sessionID");
        window.location = "/login" as any;
      }
    } catch (error) {
      console.log(`AppNavBarComponent.name.checkLogin`, error);
    }
  };

  const checkSessinInСookies = () => {
    if (!getCookie("sessionID")) {
      window.location = "/login" as any;
    } else {
      checkLogin();
    }
  };
  useEffect(() => {
    // getRolesAndUpdateState(api, dispatch);
    checkSessinInСookies();
  }, []);

  useEffect(() => {
    getRolesAndUpdateState(api, appDispatch);
  }, [selectedBot]);

  return (
    <AppNavBarComponentMain>
      <AppNavBarLogoComponent />
      <AppNavBarButtonsWrapComponent>
        <AppNavBarButtonTrComponent>
          <AppNavBarButtonComponent
            name="Bots"
            svg={<BotsIcon />}
            linkTo="/bots"
          />
          <AppNavBarButtonComponent
            name="Account"
            svg={<AccountIcon />}
            linkTo="/account"
          />
        </AppNavBarButtonTrComponent>

        <AppNavBarButtonTrComponent style={{ marginBottom: "20px" }}>
          {selectedBot &&
            Object.keys(selectedBot).length &&
            selectedBot.plan === "Starter" && (
              <AppNavProgressBarComponent
                name="Users left"
                className="custom_progressBar"
                max={100}
                current={Math.max(
                  0,
                  100 - (selectedBot.activeUsersCount ?? 100)
                )}
                linkTo={`/bots/bot/${selectedBotId}/settings/billing`}
              />
            )}
          <a
            className="a_tag_clear_default_styles"
            href="https://manual.activechat.ai"
            target="_blank"
          >
            <AppNavBarButtonComponent
              className="GrayStyleButton"
              name="Docs"
              svg={svgLib.docs("grey", { width: "24px", height: "24px" })}
              linkTo=""
            />
          </a>
        </AppNavBarButtonTrComponent>
      </AppNavBarButtonsWrapComponent>
    </AppNavBarComponentMain>
  );
};

export default AppNavBarComponent;
